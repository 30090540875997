import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Elements, } from "@stripe/react-stripe-js";
import StripeService from "../services/stripe.service";
import { increaseQuantity, lowerQuantity, removeFromCart } from "actions/cart";
import Spinner from 'react-bootstrap/Spinner';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Analytics from "analytics";
import ErrorModal from '../components/Utlility/ErrorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import CheckoutForm from "./Forms/CheckoutForm";
import FormPage from "./Forms/FormPage";
import { Container, Row, Form, Alert } from "react-bootstrap";
import styles from "./CheckoutStyles.module.scss";
// import styles from "./Forms/FormStyles.module.scss";
import bin from "images/icons/bin.png"
import Game from "./Games/Game";
import GameList from "../constants/GameList";
import GamePackList from "../constants/GamePackList";
import GameAddonList from "../constants/GameAddonList";


export default function Checkout() {
    const [clientSecret, setClientSecret] = useState("");
    const [checkoutStep, setCheckoutStep] = useState(false);
    const [checkoutItems, setCheckoutItems] = useState([]);
    const [currency, setCurrency] = useState("");
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    const [promoCode, setPromoCode] = useState("");
    const [promoBoxOpen, setPromoBoxOpen] = useState(false);
    const [checkingPromoCode, setCheckingPromoCode] = useState(false);
    const [promoCodeApplied, setPromoCodeApplied] = useState(false);
    const [promoCodeMessage, setPromoCodeMessage] = useState("");
    const [discount, setDiscount] = useState("");
    const [discountProducts, setDiscountProducts] = useState(null);
    const [email, setEmail] = useState("");
    const [showAgeWarning, setShowAgeWarning] = useState(false);

    const { items, } = useSelector(state => state.cart);
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { countryCode, } = useSelector(state => state.pricing);
    const { discountCode, } = useSelector(state => state.discount);
    const dispatch = useDispatch();

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
        console.log("Looking for discount in redux");

        if (isLoggedIn) {
            setEmail(user.email);
        }

        if (discountCode != null) {
            console.log("Found it : ", discountCode);
            setPromoCode(discountCode);
            setPromoBoxOpen(true);
            //setPromoCodeMessage(`The code ${discountCode} has been applied to your cart!`);
            //setPromoCodeApplied(true);
            checkPromotionCode(null, discountCode);
        }
    }, []);

    const appearance = {
        theme: 'flat',
        variables: {

        }
    };
    const options = {
        clientSecret,
        appearance,
        loader: "auto"
    };

    const addQuantity = (item) => {
        if (!loadingCheckout) dispatch(increaseQuantity(item));
    }
    const minusQuantity = (item) => {
        if (!loadingCheckout) dispatch(lowerQuantity(item));
    }
    const removeItem = (item) => {
        if (!loadingCheckout) dispatch(removeFromCart(item));
    }

    const checkAgeRestriction = (e) => {
        e.preventDefault();
        if (items.find(i => i.minAge >= 17)) {
            setShowAgeWarning(true);
        }
        else continueToCheckout();
    }

    const cancelAgeWarning = () => {
        setShowAgeWarning(false);
    }

    const acceptAgeWarning = () => {
        setShowAgeWarning(false);
        continueToCheckout();
    }

    const continueToCheckout = () => {
        setLoadingCheckout(true);

        var coupon = promoCodeApplied ? promoCode : "";
        StripeService.createPaymentIntent(items, coupon, email)
            .then((res) => {
                setLoadingCheckout(false);
                setCheckoutStep(true);
                console.log("res data ", res.data);
                setCartTotal(res.data.cartTotal);
                setClientSecret(res.data.clientSecret)
                setCheckoutItems(res.data.checkoutItems);
                setCurrency(res.data.currency);
                if (res.data.discountPercentage) {
                    setDiscount(`-${res.data.discountPercentage}%`);
                    setDiscountProducts(res.data.applicableProducts);
                }
                let currency = countryCode === "GB" ? "£" : "$";
                Analytics.beginCheckout(items, currency, res.data.cartTotalRaw, coupon);
            }).catch((err) => {
                console.log("Error : ", err);
                setLoadingCheckout(false);
            });
    }

    const onChangeCode = (e) => {
        if (!checkingPromoCode && !promoCodeApplied) {
            const code = e.target.value;

            setPromoCode(code);
        }
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;

        setEmail(email);
    };

    const checkPromotionCode = (e, force = null) => {
        if (e) e.preventDefault();
        let promoCodeToCheck = force || promoCode;
        console.log("promo code : ", promoCodeToCheck);
        console.log("promo code length : ", promoCodeToCheck.length)
        console.log("checking promo code : ", checkingPromoCode);
        if (promoCodeToCheck.length > 0 && !checkingPromoCode) {
            setCheckingPromoCode(true);
            setPromoCodeMessage("");
            const productIds = items.map((x) => x.productId);
            console.log("*** Checking Promo Code ***");
            StripeService.checkPromotionCode(promoCodeToCheck, productIds)
                .then((res) => {
                    console.log("Check promo code res: ", res);
                    if (res.data.valid === true) {
                        setPromoCodeApplied(true);
                        setPromoCodeMessage(res.data.message);
                    } else {
                        if (res.data.applicableProductIds && res.data.applicableProductIds.length > 0) {
                            const applicableProducts = [...GamePackList, ...GameList, ...GameAddonList].filter((x) => res.data.applicableProductIds.includes(x.productId));
                            const applicableProductNames = applicableProducts.map((x) => x.name);

                            setPromoCodeMessage(res.data.message + applicableProductNames.join(", ") + ".");
                        } else {
                            setPromoCodeMessage(res.data.message);
                        }
                    }
                    setCheckingPromoCode(false);
                }).catch((err) => {
                    console.log("Check promo code err: ", err);
                    setPromoCodeApplied(false);
                    setCheckingPromoCode(false);
                    setPromoCodeMessage(err.message);
                });
        }
    }

    const getPrice = (item) => {
        if (checkoutStep) {
            let checkoutItem = checkoutItems.find(x => x.productId === item.productId);
            let actualAmount = checkoutItem.total - checkoutItem.discountAmount;
            return `${currency}${(actualAmount / 100).toFixed(2) }`;
        } else {
            let game = [...GameList, ...GamePackList, ...GameAddonList].find((x) => x.productId === item.productId);
            let rawPrice = countryCode === "GB" ? game.pricing.gb.raw : game.pricing.us.raw;
            return (rawPrice * item.quantity).toFixed(2);
        }
    }

    const getStrikePrice = (item) => {
        let checkoutItem = checkoutItems.find(x => x.productId === item.productId);
        if (checkoutItem.discountAmount) {
            let actualAmount = checkoutItem.total
            return `${currency}${(actualAmount / 100).toFixed(2)}`;
        } else {
            return null;
        }
    }

    const isDev = () => { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    const getTotalSavings = () => {
        let amount = 0;
        checkoutItems.forEach((item) => {
            if (item.discountAmount) {
                amount += item.discountAmount;
            }
        });
        console.log(`total savings : ${amount}`)
        if (amount === 0) return null;
        else return `${currency}${(amount / 100).toFixed(2)}`;
    }

    const togglePromoBoxOpen = (value) => {
        if (!checkingPromoCode) {
            setPromoBoxOpen(value);
        }
    }

    const renderItemList = (list, title) => {
        return <table className={styles.checkoutTable}>
            <tr>
                <th>{title}</th>
                <th>Amount</th>
                <th>Price</th>
                {
                    !checkoutStep &&
                    <th></th>
                }
            </tr>
            {
                list.map(item => {
                    return renderItemRow(item);
                })
            }
        </table>
    }

    const renderItemRow = (item) => {
        return <tr>
            <td><img src={item.logo} alt={item.name} className={styles.productLogo}></img></td>
            {
                checkoutStep ?
                    <>
                        <td>
                            <div className={styles.amountPicker}>
                                <div className={`${styles.quantity} ${checkoutStep && styles.checkoutStep}`}>{item.quantity}</div>
                            </div>
                        </td>
                        {/*<td><span style={{textDecoration: "line-through", color: "darkgray"}}>{getStrikePrice(item)}</span>{`${getPrice(item)} ${discountProducts != null && (discountProducts.includes(item.productId) || discountProducts.length == 0) ? discount : ""}` || <Spinner animation="border" size="sm" />}</td>*/}


                        <td>
                            {`${getPrice(item)}` || <Spinner animation="border" size="sm" />}{` `}
                        </td>
                        {
                            getStrikePrice(item) &&
                            <td>
                                <span style={{ textDecoration: "line-through", color: "darkgray" }}>{checkoutItems.find((x) => x.productId === item.productId).discountAmount && getStrikePrice(item)}</span>
                            </td>
                        }
                    </>
                    :
                    <>
                        <td>
                            <div className={styles.amountPicker}>
                                <div className={styles.quantity}>{item.quantity}
                                    <button className={styles.minus} onClick={() => minusQuantity(item)}>
                                        {'<'}
                                    </button>
                                    <button className={styles.add} onClick={() => addQuantity(item)}>
                                        {'>'}
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>{`${getPrice(item)}` || <Spinner animation="border" size="sm" />}</td>
                        <td className={styles.deleteColumn}>
                            <button onClick={() => removeItem(item)}>
                                <FontAwesomeIcon icon={faTrash} size="xs" color="#FF5F6B" />
                                {/*                                <img src={bin} alt="remove-item"></img>*/}
                            </button>
                        </td>
                    </>
            }
        </tr>
    }

    return (
        <React.Fragment>
            <FormPage>
                <Container>
                    <Row>
                        {
                            items.length === 0 ?
                                <React.Fragment>
                                    <div className={styles.checkoutForm}>
                                        <h2 className={styles.formTitle}>Your Cart Is Empty...</h2>
                                        <Link to='/shop' className={styles.button}>Go To Shop</Link>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={`${styles.checkoutForm}`}>
                                        <h2 className={styles.formTitle}>Your Cart</h2>
                                        <p><strong>Digital Items</strong>: these games can be instantly downloaded, and no shipping will be needed, so there is no wait between you and the fun!</p>
                                        {
                                            promoCodeApplied && promoCodeMessage.length > 0 && discountCode != null &&
                                            <Alert variant={promoCodeApplied ? "success" : "danger"}>
                                                {promoCodeMessage}
                                            </Alert>
                                        }
                                        {items.filter(i => i.type === "Pack").length > 0 && renderItemList(items.filter(i => i.type === "Pack"), "Pack")}
                                        {items.filter(i => i.type === "Game").length > 0 && renderItemList(items.filter(i => i.type === "Game"), "Game")}
                                        {items.filter(i => i.type === "Addon").length > 0 && renderItemList(items.filter(i => i.type === "Addon"), "Add-on")}
                                        {
                                            checkoutStep &&
                                            <table className={styles.checkoutTable}>
                                                <tr className={styles.total}>
                                                    <td className={styles.buffer}></td>
                                                    <td className={styles.title}>Total</td>
                                                    <td>{cartTotal} {/*{discountProducts == null || discountProducts.length == 0 && discount}*/}</td>
                                                </tr>
                                                {
                                                    getTotalSavings() != null &&
                                                    <tr className={styles.totalSavings}>
                                                        <td></td>
                                                        <td>Total Savings</td>
                                                        <td>{getTotalSavings()}</td>
                                                        <td className={styles.bufferSmall}></td>
                                                    </tr>
                                                }
                                            </table>
                                        }
                                        {
                                            !loadingCheckout && !checkoutStep && <div className={styles.couponSection}>
                                                {
                                                    !promoCodeApplied && <Form.Label className={styles.title} onClick={() => togglePromoBoxOpen(!promoBoxOpen)}>Promotion Code {promoCodeApplied ? null : promoBoxOpen ? <FaMinus className={styles.icon} /> : <FaPlus className={styles.icon} />}</Form.Label>
                                                }
                                                {
                                                    promoCodeApplied ?
                                                        <React.Fragment>
                                                            {/*<div>{promoCode}</div>*/}
                                                            {
                                                                promoCodeMessage.length > 0 && discountCode == null &&
                                                                <Alert variant={promoCodeApplied ? "success" : "danger"}>
                                                                    {promoCodeMessage}
                                                                </Alert>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        promoBoxOpen &&
                                                        <React.Fragment>
                                                            <Form.Control className={styles.inp} type="text" autoComplete="off" placeholder="Enter Promotion Code" required onChange={onChangeCode} />
                                                            {
                                                                promoCodeMessage.length > 0 &&
                                                                <Alert variant={promoCodeApplied ? "success" : "danger"}>
                                                                    {promoCodeMessage}
                                                                </Alert>
                                                            }
                                                            <button onClick={checkPromotionCode} className={`${styles.button} ${styles.alternate} ${styles.small}`} style={{ marginBottom: "10px", marginTop: "10px" }}>{checkingPromoCode ? <Spinner animation="border" /> : 'Apply Code'}</button>

                                                        </React.Fragment>
                                                }

                                            </div>
                                        }
                                        {
                                            !checkoutStep &&
                                            <>
                                                <Form onSubmit={checkAgeRestriction}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Email address</Form.Label>
                                                        <Form.Control disabled={loadingCheckout} type="email" placeholder="Enter email" required onChange={onChangeEmail} value={email} />
                                                    </Form.Group>
                                                    <button className={`${styles.button} ${styles.wide}`} type="submit" id="submit">{loadingCheckout ? <Spinner animation="border" /> : 'Checkout'}</button>
                                                </Form>
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                        }
                    </Row>
                    {
                        checkoutStep &&
                        <Row xs={12}>
                            {clientSecret ?
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm email={email} />
                                </Elements>
                                :
                                <div className={`${styles.spinner}`} id="spinner"></div>
                            }
                        </Row>
                    }

                </Container>
            </FormPage>
            {
                !checkoutStep &&
                <section className={`${styles.band} ${styles.sm}`} style={{ backgroundColor: "#212B3D" }}>
                    <Container>
                        <div className='row text-center'>
                            <h2 className='text-uppercase'>{items.length === 0 ? "Add Some Games" : "More Games You'll Like"}</h2>


                                {GameList.filter(g => g.enabled || isDev()).map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} key={g.gameId} /></div>)}

                            {/* {GameList.filter(g => {g.gameId !== this.props.game.gameId }).map(g => <div className='col-md-6 col-lg-3 text-center'><Game game={g} key={g.gameId} /></div> )} */}

                            {/* {console.log(GameList.filter(g => {g.gameId !== this.props.game.gameId }))} */}
                        </div>
                    </Container>
                </section>
            }
            {showAgeWarning && <ErrorModal
                title={"Age Restriction Warning"}
                styles={`${styles.screenModel} d-flex`}
                message={"One or more items in your cart are rated for adults and are unsuitable for children, please confirm you are 17+ years old?"}
                callback={cancelAgeWarning}
                callbackText={"Cancel"}
                callback2={acceptAgeWarning}
                callbackText2={"Confirm"}
            />}
        </React.Fragment>
    );
}