import React, { Component } from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';
import Styles from './layoutStyles.module.scss'
import DiscountReader from '../DiscountReader';
import Notification from '../Notification';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <Notification />
                <DiscountReader />
                <NavMenu />
                <main tag="main" className={Styles.layout}>
                    {this.props.children}
                </main>
                <Footer />
            </div>
        );
    }
}
