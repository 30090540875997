import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Container, Row, Col, ModalBody, Modal, CloseButton, Offcanvas, Carousel } from 'react-bootstrap';
import { withRouter } from './withRouter';
import WheelComponent from './Wheel';
//import 'react-wheel-of-prizes/dist/index.css';
import Game from "../components/Games/Game";
import GameAddon from "../components/Games/GameAddon";
import GameList from "constants/GameList";
import GamePackList from "constants/GamePackList";
import GameAddonList from "constants/GameAddonList";
import styles from './HomeStyles.module.scss';
import "./HomeStyles.module.scss";

import Reviews from "constants/reviews";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselStyles.css";
import Slider from "react-slick";
import StarFull from "images/template/full-star.png";
import StarEmpty from "images/template/empty-star.png";
import promotionService from '../services/promotion.service';
import PopoutTato from "images/Avatars/potato-0.png";
import GamePack from './Games/GamePack';

import ScrawlHero from "images/heros/scrawl.jpg";
import DrawWithHero from "images/heros/draw-with-dave.jpg";
import ColourBrainHero from "images/heros/colour-brain.jpg";
import ChameleonHero from "images/heros/the-chameleon.jpg";
import CanOfSquirmsHero from "images/heros/can-of-squirms.jpg";
import HerdMentalityHero from "images/heros/herd-mentality.jpg";
import FreePlayImg from "images/FreePlayWeekend.png";

import LifeStyle1 from "images/lifestyles/lifestyle-1.png";
import LifeStyle2 from "images/lifestyles/lifestyle-2.png";
import LifeStyle3 from "images/lifestyles/lifestyle-3.png";

const heroImages = [
    ScrawlHero,
    DrawWithHero,
    ColourBrainHero,
    ChameleonHero,
    CanOfSquirmsHero,
    HerdMentalityHero,
];

const infoBoxes = [
    {
        title: "JUST LIKE JACKBOX",
        description: "Play on any screen with a web browser.\nAround the TV or even remotely.",
        image: LifeStyle1,
    },
    {
        title: "BUY ONCE, PLAY FOREVER",
        description: "Just one person needs to buy and host\n the game. Setup a game in seconds.",
        image: LifeStyle2
    },
    {
        title: "UP TO 8 PLAYERS",
        description: "No dice, no cards...no boring waiting for\nyour turn. Use your phone as a controller.",
        image: LifeStyle3
    }
];

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            isOpenPromotion: false,
            currentPromotion: null,
            segColors: [],
            segments: [],
            winningSegment: "",
            isEmailStep: false,
            promotionEmail: "",
            promotionSubmitting: false,
            promotionError: null,
            promotionSuccess: false,
            awardedPromotionCode: null,
            awardedVouchercode: null,
        };
        this.wheelRef = React.createRef(null);
    }

    carouselSettings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        customPaging: function (i) {
            return (
                <div className="carousel-dot">

                </div>
            );
        },
    };


    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        console.log(queryParams);
        if (queryParams.has("token")) {
            console.log("has params");
            setTimeout(() => this.props.navigate(`/join/?token=${queryParams.get("token")}`));
        } else {
            this.checkForPromotion();
        }
    }

    async checkForPromotion() {
        const promotionresult = await promotionService.getActivePromotion();
        if (promotionresult.data.status === "Success") {
            var date = new Date();
            var day, month, year;
            day = date.getDate();
            month = date.getMonth();
            year = date.getFullYear();
            if ((localStorage.getItem(`seen_promotion_${promotionresult.data.activePromotion.id}_${day}-${month}-${year}`) == null && localStorage.getItem(`won_promotion_${promotionresult.data.activePromotion.id}`) == null)) {
                let segments = [...this.state.segments];
                let segColors = [...this.state.segColors];
                if (promotionresult.data.activePromotion.promotionType === 0) {
                    promotionresult.data.activePromotion.promotionCodes.forEach((x, i, arr) => {
                        segments.push(x.description);
                        segColors.push("#FF5F6B");
                        if ((arr.length > 3 && i % 2 === 0) || arr.length <= 3) {
                            segments.push(" ");
                            segColors.push("#65BECD");
                        }
                    });
                } else {
                    promotionresult.data.activePromotion.promotionGames.forEach((x, i, arr) => {
                        segments.push(x.description);
                        segColors.push("#FF5F6B");
                        if ((arr.length > 3 && i % 2 === 0) || arr.length <= 3) {
                            segments.push(" ");
                            segColors.push("#65BECD");
                        }
                    });
                }

                this.setState({
                    //isOpenPromotion: true,
                    currentPromotion: promotionresult.data.activePromotion,
                    segments,
                    segColors,
                });
                //segments = this.shuffleArray(segments);
            }
        }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    submitPromotionForm = (event) => {
        event.preventDefault();
        console.log("Email : ", this.state.promotionEmail);
        console.log("promotionSubmitting : ", this.state.promotionSubmitting);

        if (this.state.promotionEmail.length > 0 && !this.state.promotionSubmitting) {
            console.log("Submitting...");
            this.setState({ promotionSubmitting: true, promotionError: null, });
            promotionService.submitPromotionEntry(this.state.currentPromotion.id, this.state.promotionEmail, this.props.pricing.countryCode)
                .then((res) => {
                    console.log(`Promotion Submit Result : `, res);
                    if (res.data.status === "Success") {
                        if (!res.data.promotionCode && !res.data.promotionGame) {
                            this.setState({ winningSegment: " " });
                        } else {
                            if (this.state.currentPromotion.promotionType === 0) {
                                this.setState({ awardedPromotionCode: res.data.promotionCode, winningSegment: res.data.promotionCode.description });
                            } else {
                                this.setState({ awardedVouchercode: res.data.promotionGame.description, winningSegment: res.data.promotionGame.description });
                            }
                            localStorage.setItem(`won_promotion_${this.state.currentPromotion.id}`, "true");
                        }

                        var date = new Date();
                        var day, month, year;
                        day = date.getDate();
                        month = date.getMonth();
                        year = date.getFullYear();
                        localStorage.setItem(`seen_promotion_${this.state.currentPromotion.id}_${day}-${month}-${year}`, "true");
                        setTimeout(() => {
                            this.wheelRef.current.spin();
                        }, 1000);

                    } else {
                        this.setState({ promotionSubmitting: false, promotionError: res.data.reason, });
                    }
                }).catch((err) => {
                    console.log(`Promotion Submit Error : `, err);
                    this.setState({ promotionSubmitting: false, promotionError: err, });
                });
        }
    }

    updatePromotionEmail = (event) => {
        const value = event.target.value;
        console.log("Updating Email");
        this.setState({ promotionEmail: value });
    }

    onFinished = (winner) => {
        console.log(winner)
        setTimeout(() => {
            this.setState({ promotionSuccess: true, });
        }, 2000);
    }

    closePromotionModal = (clearPromotion = true) => {
        if (clearPromotion) this.setState({ currentPromotion: null });
        this.setState({ isOpenPromotion: false, });
    }

    isDev() { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    isFreePlay() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = new Date('2024-10-24T00:00:00');
        const endDate = new Date('2024-10-27T23:59:59');

        if (today >= startDate && today <= endDate) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.pageHome}>
                    {/*<Helmet>*/}
                    {/*    <title>Home</title>*/}
                    {/*</Helmet>*/}
                    {/*<section className={`p-5 ${styles.heroBackground}`}>*/}
                    {/*    <Container className={`p-1 p-md-5 ${styles.heroContainer}`}>*/}
                    {/*        <Row className={styles.heroRow}>*/}
                    {/*            <h1 style={{ color: "white !important", zIndex: 1, margin: "unset", }}>Party games to play on your TV</h1>*/}
                    {/*        </Row>*/}
                    {/*        <Row className={`${styles.heroRow} my-4`} style={{ color: "white !important", position: "relative" }}>*/}
                    {/*            */}{/*<Col>*/}
                    {/*            */}{/*    <img className={styles.heroHeading} src={heroHeading} alt="" width="676" height="370"></img>*/}
                    {/*            */}{/*</Col>*/}
                    {/*            <p>*/}
                    {/*                Round up the gang, switch on your TV and get ready to play Game.City*/}
                    {/*                party games together on the big screen.*/}
                    {/*            </p>*/}
                    {/*        </Row>*/}
                    {/*        <Row className={styles.heroRow}>*/}
                    {/*            <Col xs={12} md={6}>*/}
                    {/*                <button onClick={() => this.props.navigate("/help")} className={`${styles.button} ${styles.alternate} ${styles.wide}`}>HOW DOES IT WORK</button>*/}
                    {/*            </Col>*/}
                    {/*            <Col xs={12} md={6}>*/}
                    {/*                <button onClick={() => this.props.navigate("/shop")} className={`${styles.button} ${styles.wide}`}>SHOP FOR GAMES</button>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Container>*/}
                    {/*</section>*/}
                    <section className={`${styles.heroBackground}`}>
                        <div className={styles.overlay}></div>
                        {/*<video autoPlay loop muted id='game-city-video' className={styles.video}>*/}
                        {/*    <source src={HeroVideo} type='video/mp4' />*/}
                        {/*</video>*/}
                        <Carousel className={styles.hero} controls={false} indicators={false}>
                            {
                                heroImages.map((h) => {
                                    return <Carousel.Item className={styles.carouselItem}>
                                        <img src={h} alt="Hero" className={styles.carouselImage} />
                                    </Carousel.Item>
                                })
                            }
                        </Carousel>]
                        {
                            this.isFreePlay() &&
                            <img src={FreePlayImg} className={styles.freePlayImg} />
                        }
                        <Container className={`p-1 p-md-2 ${styles.heroContainer}`}>
                            <Row className={styles.heroRow} >
                                <Col xs={12} md={6}>
                                    <button onClick={() => this.props.navigate("/shop")} className={`${styles.button} ${styles.alternate} ${styles.wide}`}>SHOP</button>
                                </Col>
                            </Row>
                            <Row className={`${styles.heroRow} d-none d-md-flex`}>
                                <Col md={6}>
                                    <button onClick={() => this.props.navigate("/join")} className={`${styles.button} ${styles.wide}`}>JOIN GAME</button>
                                </Col>
                                <Col md={6}>
                                    <button onClick={() => this.props.navigate("/lobby")} className={`${styles.button} ${styles.wide}`}>HOST GAME</button>
                                </Col>
                            </Row>
                            <Row className={`${styles.heroRow} d-md-none`}>
                                <Col xs={6}>
                                    <button onClick={() => this.props.navigate("/join")} className={`${styles.button} ${styles.medium} ${styles.wide}`}>JOIN GAME</button>
                                </Col>
                                <Col xs={6} >
                                    <button onClick={() => this.props.navigate("/lobby")} className={`${styles.button} ${styles.medium} ${styles.wide}`}>HOST GAME</button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className={`${styles.mainContent} py-4`}>
                        <Container className="pb-3">
                            <Row className={`row ${styles.infoBoxes}`}>
                                {
                                    infoBoxes.map((box, i) => {
                                        return <Col xs={12} md={4} className={`text-center my-2`}>
                                            <div className={`${styles.infoBox} p-3`}>
                                                <h3 className={styles.title}>{box.title}</h3>
                                                <p className={styles.description}>{box.description}</p>
                                                <div style={{ backgroundImage: `url(${box.image})` }} className={styles.img}>
                                                    <img src={box.image} alt={`${box.title}-lifestyleshot`} />
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Container>
                        <Container className="my-3">
                            <div className='row justify-content-center'>
                                <h2>SAVE MONEY WITH PACKS</h2>
                                {
                                    GamePackList.filter(g=>g.enabled || this.isDev()).map(g => {return <div className='col-md-6 col-xl-4 text-center'><GamePack game={g} key={g.gameId} /></div>})
                                }
                            </div>
                        </Container>
                        <Container className="text-center my-3">
                            <div className='row justify-content-center'>
                                <h2>OR BUY GAMES INDIVIDUALLY</h2>
                                {
                                    GameList.filter(g => g.enabled || this.isDev()).map(g => {return <div className='col-md-6 col-xl-4 text-center'><Game game={g} key={g.gameId} /></div>})
                                }
                            </div>
                            {/*<button onClick={() => this.props.navigate("/shop")} className={styles.button}>More Games</button>*/}
                        </Container>
                        <Container className="text-center my-3">
                            <div className='row justify-content-center'>
                                <h2>AND CHECK OUT SOME GAME ADD-ONS</h2>
                                {
                                   GameAddonList.filter(a => a.enabled || this.isDev()).map(a => <div className='col-md-6 col-lg-4 text-center'><GameAddon addon={a} /></div>)
                                }
                            </div>
                            {/*<button onClick={() => this.props.navigate("/shop")} className={styles.button}>More Games</button>*/}
                        </Container>
                    </section>
                    <section className={`${styles.reviewsSection} ${styles.band} ${styles.large}`}>
                        <Slider className={styles.carousel} {...this.carouselSettings}>
                            {
                                Reviews.map((review) => {
                                    return <div className={`${styles.reviewWidth} container p-0`}>
                                        <div className={`${styles.reviewCard} card text-center mx-3 border-0 p-3`}>
                                            <h5 className={`${styles.reviewTitle} card-title`}>{review.name}</h5>
                                            <div className="row d-flex w-50 m-auto">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="col p-1">
                                                        <img className="img-fluid m-auto" style={{}} src={i <= review.stars - 1 ? StarFull : StarEmpty} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`${styles.reviewBody} card-body`}>
                                                <p className={`${styles.reviewText} card-text`}>{review.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </section>
                </div>
                {/*{*/}
                {/*    this.state.currentPromotion != null && this.state.isOpenPromotion == false &&*/}
                {/*    <div className={styles.promotionPopout} onClick={() => this.setState({ isOpenPromotion: true })}>*/}
                {/*        <img src={PopoutTato} className={styles.popoutTato} />*/}
                {/*    </div>*/}
                {/*}*/}
                {/*<Offcanvas id="ModalPromotion" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered className={styles.modalPromotion} show={this.state.isOpenPromotion} onHide={() => this.setState({ isOpenPromotion: false })}>*/}
                {/*    <Offcanvas.Body className={`${styles.modalPromotionBody} text-center`} >*/}
                {/*        <CloseButton variant="white" style={{ display: "block", marginLeft: "auto", }} onClick={() => this.closePromotionModal(this.state.promotionSuccess)} />*/}
                {/*        {*/}
                {/*            this.state.promotionSuccess ?*/}
                {/*                <React.Fragment>*/}
                {/*                    {*/}
                {/*                        (this.state.awardedPromotionCode == null && this.state.awardedVouchercode == null) ?*/}
                {/*                            <React.Fragment>*/}
                {/*                                <div className="row py-1">*/}
                {/*                                    <p className={`${styles.promotionTitle}`}>Unlucky</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="row py-1">*/}
                {/*                                    <p className={`${styles.promotionText}`}>You didn't win this time! Come back again tomorrow to try win again!</p>*/}
                {/*                                </div>*/}
                {/*                                <div className={`form-row py-1`}>*/}
                {/*                                    <button type="button" onClick={() => this.closePromotionModal(true)} className={`${styles.promotionButton} btn btn-primary w-100`} >*/}
                {/*                                        Got it!*/}
                {/*                                    </button>*/}
                {/*                                </div>*/}
                {/*                            </React.Fragment>*/}
                {/*                            :*/}
                {/*                            <React.Fragment>*/}
                {/*                                <div className="row py-1">*/}
                {/*                                    <p className={`${styles.promotionTitle}`}>Congratulations!</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="row">*/}
                {/*                                    <p className={`${styles.promotionText}`}>{this.state.currentPromotion?.promotionType === 0 ? "You've won a discount:" : "You've won a free copy of:"}</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="row py-1">*/}
                {/*                                    <p className={`${styles.promotionDescription}`}>{this.state.currentPromotion?.promotionType === 0 ? this.state.awardedPromotionCode.description : this.state.awardedVouchercode}</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="row">*/}
                {/*                                    <p className={`${styles.promotionText}`}>Check your email for your code and instructions on how to redeem it! Please contact support if you have any issues.</p>*/}
                {/*                                </div>*/}
                {/*                                <div className={`form-row py-1`}>*/}
                {/*                                    <button type="button" onClick={this.closePromotionModal} className={`${styles.promotionButton} btn btn-primary w-100`} >*/}
                {/*                                        Awesome!*/}
                {/*                                    </button>*/}
                {/*                                </div>*/}
                {/*                            </React.Fragment>*/}
                {/*                    }*/}

                {/*                </React.Fragment>*/}
                {/*                :*/}
                {/*                <form onSubmit={this.submitPromotionForm}>*/}
                {/*                    <div className={`form-row`}>*/}
                {/*                        <h2 className={`${styles.promotionTitle}`}>{this.state.currentPromotion ? this.state.currentPromotion.promotionType === 0 ? "Want to win a discount?" : "Want to win a free game?" : ""}</h2>*/}
                {/*                    </div>*/}
                {/*                    <div className="form-row p-1">*/}
                {/*                        <p className={`${styles.promotionText}`}>Enter your email below and spin the wheel below for a chance to win!</p>*/}
                {/*                    </div>*/}
                {/*                    <div className={`form-row p-1`}>*/}
                {/*                        <input type="email" onChange={this.updatePromotionEmail} required className={`${styles.promotionInput} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email address" />*/}
                {/*                    </div>*/}
                {/*                    <div className={`form-row`}>*/}
                {/*                        <div className={styles.wheel}>*/}
                {/*                            <WheelComponent*/}
                {/*                                ref={this.wheelRef}*/}
                {/*                                segments={this.state.segments}*/}
                {/*                                winningSegment={this.state.winningSegment}*/}
                {/*                                segColors={this.state.segColors}*/}
                {/*                                onFinished={(winner) => this.onFinished(winner)}*/}
                {/*                                primaryColor='black'*/}
                {/*                                contrastColor='white'*/}
                {/*                                isOnlyOnce={true}*/}
                {/*                                size={145}*/}
                {/*                                upDuration={100}*/}
                {/*                                downDuration={1000}*/}
                {/*                                fontFamily='Futura PT'*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className={`form-row`}>*/}
                {/*                        <button type="submit" className={`${styles.promotionButton} btn btn-primary w-100`} >*/}
                {/*                            {*/}
                {/*                                this.state.promotionSubmitting ?*/}
                {/*                                    <div className={styles.loading}></div>*/}
                {/*                                    :*/}
                {/*                                    "SPIN SPIN SPIN!"*/}
                {/*                            }*/}
                {/*                        </button>*/}
                {/*                        {*/}
                {/*                            this.state.promotionError != null &&*/}
                {/*                            <div className="alert alert-danger mt-1" role="alert">*/}
                {/*                                {this.state.promotionError}*/}
                {/*                            </div>*/}
                {/*                        }*/}
                {/*                    </div>*/}
                {/*                    <div className={`form-row text-start p-1`}>*/}
                {/*                        <div className={`${styles.promotionFooterText}`}>*By submitting your email, you agree to receive marketing emails from The Games Collective. You can unsubscribe at any time. View our <a href={`${window.location.origin}/privacy-policy`} style={{ color: "white", }} target="_blank">privacy policy</a> for more. </div>*/}
                {/*                    </div>*/}
                {/*                    <div className={`form-row`}>*/}
                {/*                        <button type="button" disabled={this.state.promotionSubmitting} className={`${styles.promotionCancel}`} onClick={() => this.closePromotionModal(false)}>No Thanks</button>*/}
                {/*                    </div>*/}
                {/*                </form>*/}
                {/*        }*/}

                {/*    </Offcanvas.Body>*/}
                {/*</Offcanvas>*/}
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
    };
}

export default connect(mapStateToProps, null)(withRouter(Home));
