import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./NotificationStyles.module.scss";


export default function Notification() {
    const [isActive, setIsActive] = useState(false);
    const { roomOpen } = useSelector(state => state.room)

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = new Date('2024-10-24T00:00:00');
        const endDate = new Date('2024-10-27T23:59:59');

        if (today >= startDate && today <= endDate) {
            setIsActive(true);
        }
    }, []);


    return (
        isActive &&
        <header className={styles.appNotification}>FREE WEEKEND | PLAY FOR FREE UNTIL THE 27TH OCTOBER</header>
    );
}